/* eslint-disable react/no-array-index-key */
/**
 * Dev component for local prototyping, also may serve as a sort of scruffy library of some of our shared components
 */
import React, {
  Fragment,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useSelector } from 'react-redux';
import { FaChevronDown, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import apiNext from 'api-next';
import CodonUrls from 'urls';
import { wcagContrastChecker } from 'utils/color/colorContrastRatioCalculator';
import { mailToHref } from 'utils/commonFormattingFunctions';
import useLocalStorage from 'hooks/useLocalStorage';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import sharedStrings from 'sharedStrings';
import { useAppDispatch } from 'store';
import passiveSlice from 'store/slices/passive';

import AnnouncerMessage from 'shared-components/AriaAnnouncer/AnnouncerMessage';
import AlignmentPie from 'shared-components/AlignmentPie/AlignmentPie';
import AssessmentTakerActionBar from 'shared-components/AssessmentTakerActionBar/AssessmentTakerActionBar';
import AssessmentTakerNavItem from 'student/controllers/Course/AssessmentTakerController/AssessmentTaker/AssessmentTakerNavItem';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import CheckboxItem from 'shared-components/CheckboxItem/CheckboxItem';
import ProgressBar from 'shared-components/ProgressBar/ProgressBar';
import KebabMenu from 'shared-components/KebabMenu/KebabMenu';
import GlobalNav from 'shared-components/GlobalNav/GlobalNav';
import PracticeTestStatus from 'student/components/NextSummative/PracticeTestStatus';
import ToasterNotification, { ToasterFlavorEnum } from 'shared-components/ToasterNotification/ToasterNotification';
import ToggleSwitch from 'shared-components/ToggleSwitch/ToggleSwitch';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import Icon, { IconEnum } from 'shared-components/Icon';
import PasswordResetAction from 'shared-components/Login/PasswordResetAction';
import BarChart from 'instructor/controllers/Course/AssessmentBuilderController/components/charts/BarChart';
import AddRemoveComboButton from 'shared-components/AddRemoveButton/AddRemoveComboButton';
import { AriaLiveAttribute } from 'shared-components/AriaAnnouncer/AriaLive.types';
import { FeatureFlags } from 'App';
import { Store } from 'types/store.types';
import {
  AddRemoveEnum,
  BooleanHash,
  ClarityHash,
  ConfirmationTypeEnum,
  DirectionEnum,
  PositionEnum,
} from 'types/common.types';
import {
  AssessmentLocation,
  ClarityEnum,
  GenericObject,
  YesNo,
} from 'types/backend/shared.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { AssessmentTakerQuestionStage } from 'student/controllers/Course/AssessmentTakerController/AssessmentTakerController.types';
import { RoleEnum } from 'types/backend/roles.types';
import { PanelStatesEnum } from 'student/components/NextSummative/NextSummative.types';
import styles from 'style-config.scss';
import './Dev.scss';

const allColors = [
  ['white', styles.white],
  ['grayLight', styles.grayLight],
  ['gray', styles.gray],
  ['grayDark', styles.grayDark],
  ['black', styles.black],
  ['redDark', styles.redDark],
  ['redLight', styles.redLight],
  ['aquamarine', styles.aquamarine],
  ['greenLight', styles.greenLight],
  ['green', styles.green],
  ['greenDark', styles.greenDark],
  ['goldLight', styles.goldLight],
  ['gold', styles.gold],
  ['goldDark', styles.goldDark],
  ['coral', styles.coral],
  ['coralDark', styles.coralDark],
  ['coralLight', styles.coralLight],
  ['purpleLighter', styles.purpleLighter],
  ['purpleLight', styles.purpleLight],
  ['purple', styles.purple],
  ['purpleDark', styles.purpleDark],
  ['blueLighter', styles.blueLighter],
  ['blueLight', styles.blueLight],
  ['blue', styles.blue],
  ['blueDark', styles.blueDark],
];

const PersistedExpando = ({ id, children, header }: { id: string; children: React.ReactNode; header: React.ReactNode }) => {
  const [expandoHash, setExpandoHash] = useLocalStorage('PERSISTED_EXPANDO', {} as BooleanHash);
  // default to open
  const isExpanded = typeof expandoHash[id] === 'undefined' ? true : expandoHash[id];
  return (
    <div className="persisted-expando" data-expanded={isExpanded} id={id}>
      <button onClick={() => setExpandoHash({ ...expandoHash, [id]: !isExpanded })}>
        <FaChevronDown/>
        {header}
      </button>
      {isExpanded && children}
    </div>
  );

};

function ATABTester() {
  const [atabSwitches, setAtabSwitches] = useState({
    enableFinish: false,
    enableValidate: false,
    showFinish: false,
    vatFrozen: false,
  });
  const [clarityHash, setClarityHash] = useState<ClarityHash>({});
  const [activeL8yRef, setActiveL8yRef] = useState('GENE-AI-B1.1.008');
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const { enableFinish, enableValidate, showFinish, vatFrozen } = atabSwitches;
  const l8yRefArray = [
    'GENE-AI-B1.1.008',
    'GENE-AI-B1.3.024',
    'GENE-AI-B1.1.003',
    'GENE-AI-B1.1.006',
  ];
  const atabBase = {
    'assessmentType': AssessTypeEnum.Homework,
    'attemptLimit': 2,
    'attemptsHash': {
      'GENE-AI-B1.1.008': 1,
      'GENE-AI-B1.3.024': 2,
      'GENE-AI-B1.1.003': 1,
    },
    'currentStage': AssessmentTakerQuestionStage.INIT,
    'location': AssessmentLocation.AT,
    'isStudyPath': false,
    'surveyItemsHash': {
      'GENE-AI-B1.1.008': false,
      'GENE-AI-B1.3.024': false,
      'GENE-AI-B1.1.003': false,
      'GENE-AI-B1.1.006': false,
    },
    'itemHasQuestions': true,
    'showRecap': true,

    'vatIsFrozen': true,
    'showFinish': false,
    'enableFinish': false,
    'enableValidate': true,
  };

  return (
    <div className="dev-demo row">
      <div className="col-xs-2 column">
        <label>
          is last question&nbsp;
          <input
            type="checkbox"
            checked={isLastQuestion}
            onChange={() => setIsLastQuestion(!isLastQuestion)}
          />
        </label><br/>
        <label>
          enableFinish&nbsp;
          <input checked={enableFinish} onChange={() => setAtabSwitches((prev) => ({ ...prev, enableFinish: !enableFinish }))} type="checkbox" />
        </label><br/>
        <label>
          enableValidate&nbsp;
          <input checked={enableValidate} onChange={() => setAtabSwitches((prev) => ({ ...prev, enableValidate: !enableValidate }))} type="checkbox" />
        </label><br/>
        <label>
          showFinish&nbsp;
          <input checked={showFinish} onChange={() => setAtabSwitches((prev) => ({ ...prev, showFinish: !showFinish }))} type="checkbox" />
        </label><br/>
        <label>
          vatFrozen&nbsp;
          <input checked={vatFrozen} onChange={() => setAtabSwitches((prev) => ({ ...prev, vatFrozen: !vatFrozen }))} type="checkbox" />
        </label><br/>
      </div>
      <div className="col-xs-10">
        <AssessmentTakerActionBar
          activeL8yRef={activeL8yRef}
          clarityHash={clarityHash}
          handleClarity={(cl) => setClarityHash((prev) => ({ ...prev, [activeL8yRef]: cl }))}
          handleFinish={() => {}}
          handleNav={(dir: DirectionEnum) => {
            const idx = l8yRefArray.findIndex((ref) => ref === activeL8yRef);
            const prev = l8yRefArray[idx - 1];
            const next = l8yRefArray[idx + 1];
            switch (dir) {
              case DirectionEnum.Prev: {
                !!prev && setActiveL8yRef(prev);
                break;
              }
              case DirectionEnum.Next: {
                !!next && setActiveL8yRef(next);
                break;
              }
            }
          }}
          handleValidate={() => {}}
          l8yRefArray={l8yRefArray}
          {...atabBase}
          {...atabSwitches}
        />
      </div>
    </div>
  );
}


export default function DevBase() {
  const dispatch = useAppDispatch();
  const flags = React.useContext(FeatureFlags);
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  console.info(':: Feature Flags from context', flags);
  const user = useSelector((store: Store) => store.user);
  const [toggle, setToggle] = useState(false);
  const [activeL8yRef, setActiveL8yRef] = useState<string | null>(null);
  const [checkboxToggles, toggleCheckbox] = useReducer((checkboxState: GenericObject, key: string) => {
    const currentToggleValue = checkboxState[key] || false;
    return {
      ...checkboxState,
      [key]: !currentToggleValue,
    };
  }, {});
  const [[announcerAttribute, announcerMessage], setAnnouncerData] = useState<[AriaLiveAttribute, string]>([AriaLiveAttribute.Polite, 'Initial Announcer Message']);
  /* Global Data Fetching Effect
   * When login or reauth is sucesssful and we have a user object,
   * Grab all the non state dependent data from the API and store
   * it for use throughout the application.
   */
  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }
      const [subjects, units, topics, userEnrollments, products] = await Promise.all([
        apiNext.getSubjects(),
        apiNext.getUnits(),
        apiNext.getTopics(),
        apiNext.getUserEnrollments(user.id),
        apiNext.getProducts(),
      ]);
      const instructorCourseIds: Array<string> = [];
      const studentCourseIds: Array<string> = [];
      for (const enrollment of userEnrollments) {
        if (enrollment.roleId === RoleEnum.Instructor) {
          instructorCourseIds.push(enrollment.courseId);
        } else if (enrollment.roleId === RoleEnum.Student) {
          studentCourseIds.push(enrollment.courseId);
        }
      }
      const [instructorCourses, studentCourses] = await Promise.all([
        apiNext.getCourses(instructorCourseIds),
        apiNext.getCourses(studentCourseIds),
      ]);
      dispatch(passiveSlice.actions.setPassiveData({
        subjects,
        units,
        topics,
        instructorCourses,
        studentCourses,
        coinstructorsFromEnrolledCourses: [],
        products,
      }));
    })();

  }, [user, dispatch]);

  const pieChartData = [
    { title: "Low Bloom's", value: 3, color: styles.purple },
    { title: "High Bloom's", value: 1, color: styles.blue },
  ];
  const lineWidth = 55;
  const barData = [
    { name: 'LO 1.1', value: 3 },
    { name: 'LO 1.2', value: 2 },
    { name: 'LO 1.3', value: 1 },
    { name: 'LO 1.4', value: 6 },
    { name: 'LO 2.1', value: 0 },
    { name: 'LO 2.2', value: 5 },
    { name: 'LO 2.4', value: 7 },
  ];
  const l8yId = 'DEMO_ID';
  const questionIssueLink = mailToHref(sharedStrings.CODON_SUPPORT_EMAIL, `Report an issue with question ${l8yId}`, `What kind of issue are you encountering with question ${l8yId}?`);

  const confirmationPrompts = [
    {
      title: 'Confirmation with confirm and cancel handler',
      message: 'Hey, sup, I am the confirmation modal',
      onConfirm: () => console.info('Yup, confirm clicked, kthxbai'),
      onCancel: () => console.info('Oooh, cancel, bold'),
    },
    {
      title: 'Confirmation with confirm and cancel button but it doesn\'t do anything',
      message: 'Hey, sup, I am the confirmation modal',
      onConfirm: () => console.info('Yup, confirm clicked, kthxbai'),
      onCancel: () => {},
    },
    {
      title: 'Confirmation without cancel',
      message: 'Hey, sup, I am the confirmation modal',
      onConfirm: () => console.info('Yup, confirm clicked, kthxbai'),
    },
  ];

  const kebabItems = [
    { label: 'Mailto Link  menu item', url: questionIssueLink, external: true },
    { label: 'External Link menu item', url: CodonUrls.SupportKB, external: true },
    { label: 'Internal Link menu item', url: '/instructor' },
    { label: 'Button Menu Item', onClick: () => console.info('clicked') },
  ];

  const troof = (isTrue = false) => (
    <div className="troof" data-pass={isTrue}>
      {isTrue ? <FaCheckCircle size={20} /> : <FaTimesCircle size={20} />}
    </div>
  );

  const renderStatus = (fg: string, bg: string) => {
    const { regularText, largeText } = wcagContrastChecker(fg, bg);
    return (
      <div className="status-cell">
        <div>
          {/*<span>regularText:</span>*/}
          <span>{troof(regularText.aa)}</span>
        </div>
        <div>
          {/*<span>largeText:</span>*/}
          <span>{troof(largeText.aa)}</span>
        </div>
      </div>
    );
  };

  const messagesToAnnounce: Array<[AriaLiveAttribute, string]> = [
    [AriaLiveAttribute.Assertive, 'Assertive Announcement'],
    [AriaLiveAttribute.Polite, 'Polite Announcement'],
    [AriaLiveAttribute.Assertive, 'Another Assertive Announcement'],
    [AriaLiveAttribute.Polite, 'Another Polite Announcement'],
  ];

  const navItemQuestions = [
    {
      l8yId: 'boof1',
      showCorrectness: false,
      questionStatus: {
        allORQs: true,
        canRecap: true,
        clarity: ClarityEnum.Muddy,
        isCorrect: YesNo.Yes,
        isSurveyItem: true,
      },
    },
    {
      l8yId: 'boof2',
      showCorrectness: false,
      questionStatus: {
        allORQs: false,
        canRecap: true,
        clarity: ClarityEnum.Clear,
        isCorrect: YesNo.Yes,
        isSurveyItem: true,
      },
    },
    {
      l8yId: 'boof3',
      showCorrectness: true,
      questionStatus: {
        allORQs: false,
        canRecap: true,
        clarity: ClarityEnum.Muddy,
        isCorrect: YesNo.No,
        isSurveyItem: false,
      },
    },
    {
      l8yId: 'boof4',
      showCorrectness: true,
      questionStatus: {
        allORQs: false,
        canRecap: true,
        clarity: ClarityEnum.Muddy,
        isCorrect: YesNo.Yes,
        isSurveyItem: false,
      },
    },
    {
      l8yId: 'boof5',
      showCorrectness: true,
      questionStatus: {
        allORQs: false,
        canRecap: true,
        clarity: ClarityEnum.Clear,
        isCorrect: YesNo.Yes,
        isSurveyItem: false,
      },
    },
  ];

  return (
    <div className="instructor-base">
      <div className="app_top"><GlobalNav isInstructor={false} /></div>
      {Object.values(ToasterFlavorEnum).map((flav) => (
        <ToasterNotification
          key={flav}
          id={`dev-toaster__${flav}`}
          flavor={flav}
          message={`This is the text of the toaster notification with ${flav} flavor`}
        />
      ))}
      <div className="dev__main">
        <div className="dev-demo row">
          <div className="col-xs-12">
            <div className="h3">
              Announcement Testing
            </div>
            <ul>
              {messagesToAnnounce.map(([attribute, msg]) => (
                <li key={msg}>
                  <BetterButton
                    onClick={() => setAnnouncerData([attribute, `Hello: this is ${msg}`])}
                    text={msg}
                    aria-label={`Hello: this is ${msg}`}
                    primary
                  />
                </li>
              ))}
            </ul>
            <AnnouncerMessage aria-live={announcerAttribute} message={announcerMessage} />
          </div>
        </div>
        <ATABTester />
        <div className="dev-demo row">
          <div className="col-xs-12">
            <div className="h3">
              Font Sizes
            </div>
            <div className="font-size-xxs">font-size-xxs</div>
            <div className="font-size-xs">font-size-xs</div>
            <div className="font-size-sm">font-size-sm</div>
            <div className="font-size-md">font-size-md</div>
            <div className="font-size-lg">font-size-lg</div>
            <div className="font-size-xl">font-size-xl</div>
            <div className="font-size-xxl">font-size-xxl</div>
          </div>
        </div>
        {/* NOTE: This won't actually work because there's no auth token in the params but it's here for testing locally*/}
        <PasswordResetAction />
        <div className="dev-demo row">
          {Object.values(PanelStatesEnum).map((pState, idx) => (
            <div className="col-xs-12 col-sm-2" key={pState}>
              <small>{Object.keys(PanelStatesEnum)[idx]}</small>
              <PracticeTestStatus
                firstHomework={{
                  name: 'homework Cypress Assessment edited',
                  id: '1400e76e-841e-4b7d-bd09-f29473ba8bab',
                  mergedDueDate: '2023-05-11T04:59:00.000Z',
                }}
                practiceTest={{
                  latestCorrectQuestionCount: 2,
                  totalQuestions: 5,
                }}
                summaryInfo={{
                  questionsForRecapture: 1,
                  muddyCount: 3,
                }}
                panelState={pState}
              />
            </div>
          ))
          }
        </div>
        <div className="dev-demo row">
          <div className="col-md-6">
            <div className="h3">
              CheckboxItem
            </div>
          </div>
          <div className="col-md-6 kebab-demo row">
            <div className="col-md-6">
              <div>CheckboxItem solo</div>
              <CheckboxItem
                selected={!!checkboxToggles.default_checked}
                onClick={() => toggleCheckbox('default_checked')}
                label="Toggle"
              />
              <CheckboxItem
                selected={!!checkboxToggles.default_unchecked}
                onClick={() => toggleCheckbox('default_unchecked')}
                label="Toggle Two"
              />
            </div>
            <div className="col-md-6">
              <div>CheckboxItem list</div>
              <ul className="list-reset">
                {Array.from({ length: 6 }).map((x, idx) => (
                  <li key={`${idx}${checkboxToggles[`checkbox${idx}`]}`}>
                    <CheckboxItem
                      selected={checkboxToggles[`checkbox${idx}`]}
                      onClick={() => toggleCheckbox(`checkbox${idx}`)}
                      label={checkboxToggles[`checkbox${idx}`] ? 'Checked' : 'Unchecked'}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="dev-demo row">
          <div className="col-md-6">
            <div className="h3">
              KebabMenu
            </div>
            <input placeholder="keyboard focus here" />
          </div>
          <div className="col-md-6 kebab-demo">
            <KebabMenu
              items={kebabItems}
              position={PositionEnum.Bottom}
            />
            <AddRemoveComboButton
              items={kebabItems}
              allowAdd={true}
              allowRemove={false}
              handleAdd={() => {}}
              handleRemove={() => {}}
              isProcessing={false}
              verb={AddRemoveEnum.Add}
              title="Split Button Add"
            />
            <KebabMenu
              items={kebabItems}
              position={PositionEnum.Top}
            />
          </div>
        </div>
        <div className="dev-demo row">
          <div className="col-md-6">
            <div className="h3">
              ToggleSwitch
            </div>
            <input placeholder="keyboard focus here" />
          </div>
          <div className="col-md-6 kebab-demo">
            <div className="toggle-wrap">
              <ToggleSwitch
                id='isGradeSyncEnabled'
                label="Regular Toggle"
                checked={toggle}
                disabled={false}
                onChange={() => setToggle(!toggle)}
              />
              <ToggleSwitch
                data-on="YES"
                data-off="NO"
                id='isGradeSyncEnabled'
                label="Toggle With Custom Labels"
                checked={toggle}
                onChange={() => setToggle(!toggle)}
              />
              <ToggleSwitch
                id='isGradeSyncEnabled'
                label="Disabled Toggle"
                checked={toggle}
                disabled
                onChange={() => setToggle(!toggle)}
              />
            </div>
          </div>
        </div>
        <div className="dev-demo row">
          <div className="col-xs-12 col-sm-9">
            <div className="h3">
              NavItem
            </div>
          </div>
          <div className="col-xs-12 col-sm-3 nav-menu__wrap">
            <nav className="assessment-taker-nav-menu" aria-label="Assessment Table of Contents">
              <div className="nav-item__header"></div>
              <ul className="nav-item-list">
                {navItemQuestions.map(({ l8yId: qL8yId, questionStatus, showCorrectness }, idx) => (
                  <AssessmentTakerNavItem
                    handleClick={() => setActiveL8yRef(qL8yId)}
                    isActive={activeL8yRef === qL8yId}
                    itemIndex={idx}
                    l8yId={qL8yId}
                    key={qL8yId}
                    questionStatus={questionStatus}
                    showCorrectness={showCorrectness}
                  />
                ))}
              </ul>
              <div className="nav-item__footer"></div>
            </nav>
          </div>
        </div>
        <div className="dev-demo row">
          <div className="col-md-6">
            <div className="h3">
              Headings from the style library
            </div>
          </div>
          <div className="col-md-6">
            <div className="dev__styles">
              <div className="h1">Heading .h1</div>
              <div className="h2">Heading .h2</div>
              <div className="h3">Heading .h3</div>
              <div className="h4">Heading .h4</div>
              <div className="h5">Heading .h5</div>
            </div>
          </div>
          <div className="dev__confirmation-prompts">
            {Object.values(ConfirmationTypeEnum).map((confirmationType) =>
              confirmationPrompts.map(({ title, message, onConfirm, onCancel }) => (
                <div key={`${confirmationType}_${title}`}>
                  <button
                    onClick={() => triggerConfirmationPrompt({
                      confirmationType,
                      title: `${confirmationType}: ${title}`,
                      message,
                      onConfirm,
                      onCancel,
                    })}
                  >
                    {confirmationType}: {title}
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="tooltip-dev">
          <div className="h3">
            Tooltips
          </div>
          {
            Object.values(PositionEnum).map((pos, i) => (
              <Fragment key={`dev-tooltip-${pos}`}>
                <BetterTooltip
                  content={() => `${pos}: this is a lot of text`}
                  position={pos}
                  indicate={i % 2 === 0}
                >
                  {pos.toUpperCase()}
                </BetterTooltip>
              </Fragment>
            ))
          }
        </div>
        <PersistedExpando id="graphs" header={(<div className="h3">Graphs 'n' Such</div>)}>
          <div className="dev-demo row">
            <div className="col-xs-4">
              <BarChart data={barData} handleHover={() => {}} />
            </div>
            <div className="col-xs-4">
              <div className="piechart-legend">
                {
                  pieChartData.map(({ title, color }) => (
                    <div className="piechart-legend__label" key={title}>
                      <div className="piechart-legend__color" style={{ backgroundColor: color }}>&nbsp;</div>
                      <div className="piechart-legend__title">{title}</div>
                    </div>
                  ))
                }
              </div>
              <PieChart
                lineWidth={lineWidth}
                radius={40}
                label={({ dataEntry }) => dataEntry.value}
                labelPosition={100 - lineWidth / 2}
                labelStyle={{
                  fill: '#fff',
                  opacity: 0.75,
                  pointerEvents: 'none',
                }}
                startAngle={-90}
                data={pieChartData}
              />
            </div>
            <div className="col-xs-4">
              {[0, 8, 12, 15, 18, 20, 25, 30, 40, 50, 60, 70, 80, 100].map((perc, i) => (
                <Fragment key={`${perc}`}>
                  <ProgressBar
                    percentage={perc}
                    color={i % 2 === 0 ? styles.black : styles.purple}
                    displayPercentage={true}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </PersistedExpando>
        <PersistedExpando id="icons" header={(<div className="h3">Codon Icons</div>)}>
          <div className="icons-list">
            {Object.values(IconEnum).map((which, idx) => (
              <div key={which}>
                <Icon size={36} which={which} />
                {Object.keys(IconEnum)[idx]}
              </div>
            ))}
          </div>
        </PersistedExpando>
        <PersistedExpando id="alignment-pie" header={(<div className="h3">AlignmentPie</div>)}>
          <div className="dev-demo row">
            <div className="col-xs-4">
            </div>
            <div className="col-xs-2">
              <AlignmentPie />
            </div>
            <div className="col-xs-2">
              <AlignmentPie greenSlice />
            </div>
            <div className="col-xs-2">
              <AlignmentPie coralSlice />
            </div>
            <div className="col-xs-2">
              <AlignmentPie blueSlice />
            </div>
          </div>
        </PersistedExpando>
        <div className="color-town">
          <PersistedExpando id="color-town" header={(<div className="h3">WCAG AA Color Contrast Table</div>)}>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th colSpan={2}>white text</th>
                  <th colSpan={2}>black text</th>
                  <th colSpan={2}>white bg</th>
                  <th colSpan={2}>black bg</th>
                </tr>
              </thead>
              <tbody>
                {allColors.map(([name, color]) => (
                  <tr className="color-town-row" key={name}>
                    <td className="color-town-row__name">{name}</td>
                    <td className="swatch" style={{ color: styles.white, backgroundColor: color }}>
                      regular text
                      <div className="wcag-large-text">Large Text</div>
                    </td>
                    <td>
                      {renderStatus(styles.white, color)}
                    </td>
                    <td className="swatch" style={{ color: styles.black, backgroundColor: color }}>
                      regular text
                      <div className="wcag-large-text">Large Text</div>
                    </td>
                    <td>
                      {renderStatus(styles.black, color)}
                    </td>
                    <td className="swatch" style={{ color, backgroundColor: styles.white }}>
                      regular text
                      <div className="wcag-large-text">Large Text</div>
                    </td>
                    <td>
                      {renderStatus(color, styles.white)}
                    </td>
                    <td className="swatch" style={{ color, backgroundColor: styles.black }}>
                      regular text
                      <div className="wcag-large-text">Large Text</div>
                    </td>
                    <td>
                      {renderStatus(color, styles.black)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PersistedExpando>
        </div>
        <PersistedExpando id="loading-spinner" header={(<div className="h3">Loading Spinner</div>)}>
          <h3>Timeoutable</h3>
          <LoadingSpinner loadingMessage="Testing the Loading Timeout" />
          <h3>Never timeout</h3>
          <LoadingSpinner loadingMessage="Just Keep Spinning" shouldTimeout={false} />
        </PersistedExpando>
      </div>
    </div>
  );
}
